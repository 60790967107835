<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Бадже
      </h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="dialogFormVisible = true"
          >Нэмэх</el-button
        >
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <!-- <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх үйлчилгээний нэр"
            /> -->
          </div>
          <el-table
            :data="BadgesList"
            :default-sort="{ prop: 'date', order: 'descending' }"
            size="mini"
            style="width: 100%"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="name_mon" label="Нэр /монгол/">
            </el-table-column>
            <el-table-column prop="name_eng" label="Нэр /англи/">
            </el-table-column>
            <el-table-column prop="color" label="Өнгө">
              <template slot-scope="scope">
                <el-color-picker
                  v-model="scope.row.color"
                  disabled
                  size="mini"
                ></el-color-picker>
              </template>
            </el-table-column>
            <el-table-column label="Үйлдэл">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="showEdit(scope.row)"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteBadge(scope.row)"
                  round
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="Бадже нэмэх" :visible.sync="dialogFormVisible">
      <el-form :model="createBadge">
        <el-form-item label="Нэр /монгол/">
          <el-input
            v-model="createBadge.name_mon"
            placeholder="Бадже монгол нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Нэр /англи/">
          <el-input
            v-model="createBadge.name_eng"
            placeholder="Бадже англи нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Өнгө">
          <el-select
            v-model="createBadge.color"
            size="mini"
            placeholder="Select"
          >
            <el-option
              v-for="item in color"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-color-picker
          size="mini"
          disabled
          v-model="createBadge.color"
        ></el-color-picker>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Буцах</el-button>
        <el-button type="success" @click="addCreateBadge()">Нэмэх</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Бадже засах" :visible.sync="dialogEditFormVisible">
      <el-form :model="editBadge">
        <el-form-item label="Нэр /монгол/">
          <el-input
            v-model="editBadge.name_mon"
            placeholder="монгол нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Нэр /англи/">
          <el-input
            v-model="editBadge.name_eng"
            placeholder="англи нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Өнгө">
          <el-select v-model="editBadge.color" size="mini" placeholder="Select">
            <el-option
              v-for="item in color"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-color-picker
          size="mini"
          disabled
          v-model="editBadge.color"
        ></el-color-picker>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEditFormVisible = false">Буцах</el-button>
        <el-button type="success" @click="updateEdit()">Нэмэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
export default {
  created() {
    this.getBadges();
  },
  data() {
    return {
      color: [
        {
          value: "#F36569",
          label: "Улаан"
        },
        {
          value: "#FFB500",
          label: "Шар"
        },
        {
          value: "#7CBB00",
          label: "Ногоон"
        }
      ],
      editBadge: [],
      BadgesList: [],
      createBadge: {
        name_mon: "",
        name_eng: "",
        color: ""
      },
      dialogFormVisible: false,
      color2: null,
      dialogEditFormVisible: false,
      deleteSendData: {
        id: 0
      }
    };
  },
  methods: {
    closeProgress() {
      this.dialogFormVisible = false;
      this.dialogEditFormVisible = false;
    },
    getBadges() {
      service.getBadges().then(res => {
        this.BadgesList = res.data.data.rows;
      });
    },
    addCreateBadge() {
      service.createBadge(this.createBadge).then(response => {
        if (response.data.data.id !== null) {
          this.$notify({
            title: "Амжилттай",
            message: "Бадже мэдээлэл шинээр нэмэгдлээ",
            type: "success"
          });
          this.closeProgress();
        } else if (response.data.data.id == null) {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Бадже мэдээлэл нэмэхэд алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
        }
        this.getBadges();
      });
    },
    showEdit(row) {
      this.editBadge = row;
      this.dialogEditFormVisible = true;
    },
    updateEdit() {
      service.updateBadge(this.editBadge.id, this.editBadge).then(response => {
        if (response.data.status === "success") {
          this.$notify({
            title: "Амжилттай",
            message: "Бадже мэдээлэл засагдлаа",
            type: "success"
          });
          this.closeProgress();
        } else if (response.data.status !== "unsuccess") {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Бадже мэдээлэл засахад алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
        }
        this.getBadges();
      });
    },
    deleteBadge(data) {
      this.deleteSendData.id = data.id;
      this.$confirm("Устгахдаа итгэлтэй байна уу?", data.name_mon, {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteBadge(data.id).then(response => {
            if (response.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Бадже устгагдлаа",
                type: "success"
              });
            } else if (response.data.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Бадже устгахад алдаа гарлаа " + " " + response.error.message,
                type: "warning"
              });
            }
            this.getBadges();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
        });
    }
  }
};
</script>
